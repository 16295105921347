import React from 'react'
import styled from "styled-components"
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import "../assets/stylesheets/footer.scss"
import useSiteMetadata from '../hooks/use-site-config'
import useSiteImages from '../hooks/use-site-images'
import { Container, Row, Col } from 'reactstrap'
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { graphql } from 'gatsby'

export const footerQuery = graphql`query footerQuery{
  logoFooter: file(relativePath: {eq: "assets/images/onePage/LogoHome.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}

`
const FooterContainer = styled.footer`
bottom: 0;
background-color: black;
color: white;
margin-top: 2rem;
padding: 2rem 0;
display: table;
width: 100%;
overflow: hidden;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
position: inherit;
` 
const ImageFooter = styled(GatsbyImage)`
width: 256px !important;
height: 72.6px !important;
margin: auto;
top: 1.8rem;
@media (min-width: 320px) and (max-width: 767px) {
  top: 0 !important;
  margin: 20px auto !important;
}
@media only screen and (min-width: 768px) and (max-width: 991px){
      top: -40px !important;
      max-width: 100% !important;
      flex: none !important;
      margin: 20px auto !important;
}
`

const Footer = ({ props, className }) => {
  const { imageLogo } = useSiteMetadata()
  const image  = useSiteImages(imageLogo)

  return (
    <FooterContainer>
     <Container>
       <Row className="footer-row">
         <Col className="imageFooter" lg="4" md="4" sm="12">
            <ImageFooter image={getImage(image)} />
          </Col >
         
         <Col className="col-footer" lg="3" md="4" sm="12">
         <h5>STACKTRACE</h5>
         <ul>
           <a href="https://api.whatsapp.com/send?phone=54 9 2664688209 &text=Hola estoy interesado en sus servicios"><li><PhoneIcon/>+54 9 2664688209</li></a>
           <li><LocationOnIcon/>Ciudad de San Luis, Argentina</li>
         </ul>
         </Col>
         
         <Col className="col-footer" lg="3" md="4" sm="12">
         <h5>SERVICIOS</h5>
         <ul>
           <a href="/services#soft"><li><ArrowRightIcon />Desarrollo de software</li></a>
           <a href="/services#apps"><li><ArrowRightIcon />Desarrollo de apps móviles</li></a>
           <a href="/services#especial"><li><ArrowRightIcon />Equipos de espcialidad</li></a>
           <a href="/services#engineering"><li><ArrowRightIcon />Ingeniería de calidad</li></a>
           <a href="/services#nfc"><li><ArrowRightIcon />Tecnología NFC mobile</li></a>
         </ul>
         </Col>

         <Col className="col-footer" lg="2" md="4" sm="12">
         <h5>EMPRESA</h5>
         <ul>
            <a href="/#about"><li><ArrowRightIcon />Nosotros</li></a>
            <a href="/#contact"><li><ArrowRightIcon />Contacto</li></a>
         </ul>
         </Col>
       </Row>
     </Container>
       
    </FooterContainer>
  )
}

export default Footer
