import { useStaticQuery, graphql } from 'gatsby'

const useSiteMetadata = () => {
  const result = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          headLogo
          siteTitle
          siteCover
          siteFooter
          authorName
          siteDescription
          headerTitle
          headerLinksIcon 
          appleStore
          playStore
          imageLogo
          makeProcedureIcon {
            title
            law
            notary
            imageName
            link
          }
          locationProcedureIcon {
            title
            imageName
            link
          }
          googleMapsUrl
        }
      }
    }
  `)
  return result.site.siteMetadata
}

export default useSiteMetadata
