import React from 'react';
import config from '../contac.js';
import styled from 'styled-components'

const Social = styled.div`

  a {
    text-align: center;
    height: 2rem;
    width: 2rem;
    background:white !important;
    border-radius: 100%;
    line-height: 2rem;
    color: black !important;
    &:hover {
      color: #00eb9e ;
    }
    &:active {
      color: white !important;
    }
  
}
`


export default function SocialLinks() {
  return (
            <Social className="d-flex justify-content-center">
          {config.socialLinks.map(social => {
            const { icon, url } = social;
            return (
              <a key={url} href={url} className={`mx-2 `}>
                <i className={`fab ${icon}`}></i>
              </a>
            );
          })}
        </Social>
  );
}
