module.exports = {
socialLinks: [
   
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://facebook.com/',
    },
    {
        icon: 'fa-instagram',
        name: 'Instagram',
        url: 'https://instagram.com/',
      },
  ],
  email: 'anubhav.srivastava00@gmail.com',
  phone: '000-00000',
  address: '305 Del Sol, Sunnyvale, CA',
};
